<template>
  <div>
    <router-view></router-view>
    <van-tabbar
      v-model="active"
      active-color="#333333"
      inactive-color="#999999"
      route
      placeholder
    >
      <van-tabbar-item :to="identity == 1 ? '/channel' : '/myCard'">
        <span>{{ identity == 1 ? "渠道" : "会员卡" }}</span>
        <template #icon="props">
          <img :src="props.active ? channel.active : channel.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/purchaseCard">
        <template #icon="props">
          <img class="buy" :src="props.active ? buy.active : buy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/data">
        <span>数据</span>
        <template #icon="props">
          <img :src="props.active ? data.active : data.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {},
  created() {},
  data() {
    return {
      identity: localStorage.identity,
      active: 0,
      channel: {
        active:
          localStorage.identity == 1
            ? "https://t.yhaocang.com/upload/sf_static/img/tabbar/qudao1.png"
            : "https://t.yhaocang.com/upload/sf_static/img/tabbar/huiyuan1.png",
        inactive:
          localStorage.identity == 1
            ? "https://t.yhaocang.com/upload/sf_static/img/tabbar/qudao2.png"
            : "https://t.yhaocang.com/upload/sf_static/img/tabbar/huiyuan2.png"
      },
      data: {
        active: "https://t.yhaocang.com/upload/sf_static/img/tabbar/data1.png",
        inactive: "https://t.yhaocang.com/upload/sf_static/img/tabbar/data2.png"
      },
      buy: {
        active: "https://t.yhaocang.com/upload/sf_static/img/tabbar/buy.png",
        inactive: "https://t.yhaocang.com/upload/sf_static/img/tabbar/buy.png"
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.buy {
  width: 121px;
  height: 117px;
}
/deep/.van-tabbar {
  background-color: #f7f7f7;
}
/deep/ .van-tabbar-item--active {
  background-color: #f7f7f7;
}
</style>
